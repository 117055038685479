body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  height: auto;
  text-align: center;
  overflow-x: visible;
  /* border: #282c34 solid 1px; */
}

.custom-username-input {
  height: calc(100vh - 63px);
}

.custom-body-height-allchat {
  height: calc(100vh - 175px);
}
